
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import useAutomationRuleOpenModal from '@/compositions/services/useAutomationRuleOpenModal'

export default defineComponent({
  components: {
    TopFilterButton,
    TopFilter,
  },
  setup() {
    const { openAutomationRuleAddKeyword } = useAutomationRuleOpenModal('keyword')

    return {
      openAutomationRuleAddKeyword,
    }
  },
})
