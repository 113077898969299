import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_services_automation_rules_keywords_filter = _resolveComponent("services-automation-rules-keywords-filter")!
  const _component_services_automation_rules_keywords_table = _resolveComponent("services-automation-rules-keywords-table")!
  const _component_services_automation_rules_keywords_empty = _resolveComponent("services-automation-rules-keywords-empty")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isEmptyMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_services_automation_rules_keywords_filter, {
            search: _ctx.search,
            "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
          }, null, 8, ["search"]),
          _createVNode(_component_services_automation_rules_keywords_table, {
            headers: _ctx.tableHeaders,
            items: _ctx.filteredTableItems
          }, null, 8, ["headers", "items"])
        ], 64))
      : (_openBlock(), _createBlock(_component_services_automation_rules_keywords_empty, { key: 1 }))
  ]))
}