
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import ServicesAutomationRulesKeywordsDropdown from '@/components/pages/services/automationRules/keywords/ServicesAutomationRulesKeywordsDropdown.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { AutomationRuleKeyword } from '@/definitions/services/automationRules/types'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    TmTable,
    TmBadge,
    TmStatus,
    ServicesAutomationRulesKeywordsDropdown,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array as PropType<AutomationRuleKeyword[]>,
      required: true,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
})
