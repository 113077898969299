
import {
  defineComponent,
  ref,
  computed
} from 'vue'
import ServicesAutomationRulesKeywordsTable from '@/components/pages/services/automationRules/keywords/ServicesAutomationRulesKeywordsTable.vue'
import ServicesAutomationRulesKeywordsFilter from '@/components/pages/services/automationRules/keywords/ServicesAutomationRulesKeywordsFilter.vue'
import ServicesAutomationRulesKeywordsEmpty from '@/components/pages/services/automationRules/keywords/ServicesAutomationRulesKeywordsEmpty.vue'
import { useModes } from '@/compositions/modes'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import type { AutomationRuleKeyword } from '@/definitions/services/automationRules/types'
import type { TableHeaders } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    ServicesAutomationRulesKeywordsTable,
    ServicesAutomationRulesKeywordsFilter,
    ServicesAutomationRulesKeywordsEmpty,
  },
  setup() {
    const { isEmptyMode } = useModes()

    const search = ref('')
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Keyword', value: 'keyword-slot' },
      { text: 'Status', value: 'status-slot' },
      { text: 'Used in', value: 'used-in-slot' },
      { text: 'Date created', value: 'dateCreated', format: (val: string) => formatDate(val) },
    ])

    const tableItems = getTableData('automationKeywords') as AutomationRuleKeyword[]

    const filteredTableItems = computed(() => tableItems.filter((el) => (el.keyword.toLocaleLowerCase().includes(search.value.toLocaleLowerCase()))))

    return {
      search,
      tableHeaders,
      filteredTableItems,
      isEmptyMode,
    }
  },
})
