
import { defineComponent } from 'vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import TmButton from '@/components/shared/TmButton.vue'
import useAutomationRuleOpenModal from '@/compositions/services/useAutomationRuleOpenModal'

export default defineComponent({
  components: {
    TmButton,
    TmEmptyState,
  },
  setup() {
    const { openAutomationRuleAddKeyword } = useAutomationRuleOpenModal('keyword')

    return {
      openAutomationRuleAddKeyword,
    }
  },
})
