import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_services_automation_rules_keywords_dropdown = _resolveComponent("services-automation-rules-keywords-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    "items-name": "keyword"
  }, {
    "body-cell-keyword-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_badge, { static: "" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(row.keyword.toUpperCase()), 1)
        ]),
        _: 2
      }, 1024)
    ]),
    "body-cell-status-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(row.status)), null, 16)
    ]),
    "body-cell-used-in-slot": _withCtx(({ row }) => [
      _createTextVNode(_toDisplayString(row.usedIn) + " rules ", 1)
    ]),
    "body-cell-actions": _withCtx((props) => [
      _createVNode(_component_services_automation_rules_keywords_dropdown, {
        rule: props.row
      }, null, 8, ["rule"])
    ]),
    _: 1
  }, 8, ["headers", "items"]))
}